import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersDetails, fetchUser, fetchUserMe } from "./actions";

const initialState = {
  userMe: {
    data: null,
    isLoading: false,
    error: null,
    success: null,
  },
  organizations: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  agencies: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  streamers: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  users: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // FETCH USER ME
      .addCase(fetchUserMe.pending, (state, action) => {
        state.userMe.isLoading = true;
      })
      .addCase(fetchUserMe.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;

        state.userMe.data = data;
        state.userMe.isLoading = false;
        state.userMe.success = message || `Successfully Fetched User Me`;

      })
      .addCase(fetchUserMe.rejected, (state, action) => {
        state.userMe.isLoading = false;
        state.userMe.success = null;
        state.userMe.error = action.payload.m || `Error Fetched User Me`;
      })

      // THIS IS REUSABLE FOR FETCHING USERS, ORGANIZATION, AGENCIES AND STREAMERS
      .addCase(fetchUser.pending, (state, action) => {
        const { userType } = action.meta.arg
        state[userType].isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const { userType } = action.meta.arg
        const data = action.payload.d;
        const message = action.payload.m;

        state[userType].data = data;
        state[userType].isLoading = false;
        state[userType].success = `Successfully Fetched ${userType} Data`;
        state[userType].message = message;

      })
      .addCase(fetchUser.rejected, (state, action) => {
        const { userType } = action.meta.arg
        state[userType].isLoading = false;
        state[userType].success = false;
        state[userType].error = action.payload.m || `Error Fetched Agency Details`;
        state[userType].message = action.payload.m || "An error occurred";
      })


      // THIS IS REUSABLE FOR FETCHING USER, ORGANIZATION, AGENCY AND STREAMER DETAILS
      .addCase(fetchUsersDetails.pending, (state, action) => {
        const { userType } = action.meta.arg
        state[userType].isLoading = true;
      })
      .addCase(fetchUsersDetails.fulfilled, (state, action) => {
        const { userType } = action.meta.arg
        const data = action.payload.d;
        const message = action.payload.m;

        state[userType].userDetails = data;
        state[userType].isLoading = false;
        state[userType].success = `Successfully Fetched ${userType} Details`;
        state[userType].message = message;

      })
      .addCase(fetchUsersDetails.rejected, (state, action) => {
        const { userType } = action.meta.arg
        state[userType].isLoading = false;
        state[userType].success = false;
        state[userType].error = action.payload.m || `Error Fetched ${userType} Details`;
        state[userType].message = action.payload.m || "An error occurred";
      })

  },
});

export const { 
} = userSlice.actions;

export default userSlice.reducer;

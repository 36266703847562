import { createSlice } from "@reduxjs/toolkit";
import { fetchStreamersLeaderBoard, getTotalLiveStreamers, getTotalLiveViewers, getTotalRevenue } from "./actions";

const initialState = {
  data: null,
  totalRevenue: 0,
  totalLiveViewers: 0,
  totalLiveStreamers: 0,
  streamerLeaderBoard: null,
  isLoading: false,
  error: null,
  success: null,
  message: null
};

const analyticSlice = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalRevenue.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTotalRevenue.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalRevenue = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(getTotalRevenue.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(getTotalLiveViewers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTotalLiveViewers.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalLiveViewers = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(getTotalLiveViewers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(getTotalLiveStreamers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTotalLiveStreamers.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalLiveStreamers = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(getTotalLiveStreamers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchStreamersLeaderBoard.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchStreamersLeaderBoard.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.streamerLeaderBoard = data;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchStreamersLeaderBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })
  },
});

export const { 
} = analyticSlice.actions;

export default analyticSlice.reducer;

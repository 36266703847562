import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/api_service";
import buildQueryString from "helpers/buildQueryString";

export const fetchPayoutList = createAsyncThunk(
  "payout/fetchPayoutList",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts?${queryString}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error logging in");
    }
  }
);

export const fetchAvailableBalance = createAsyncThunk(
  "payout/fetchAvailableBalance",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.get(`/payouts/available-balance`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchMontlyProfit = createAsyncThunk(
  "payout/fetchMontlyProfit",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts/monthly-profit`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchWeeklyEarnings = createAsyncThunk(
  "payout/fetchWeeklyEarnings",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts/weekly-earnings`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchLastPayout = createAsyncThunk(
  "payout/fetchLastPayout",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts/last-payout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchPayoutHistory = createAsyncThunk(
  "payout/fetchPayoutHistory",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts/payout-history?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchGiftHistory = createAsyncThunk(
  "payout/fetchGiftHistory",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts/gifting-history?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const requestPayout = createAsyncThunk(
  "auth/requestPayout",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/payouts/request-payout`, payload.data, {});
      // console.log("--ACTION requestPayout API RESPONSE: ", response);
      return response.data;
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error requestPayout");
    }
  }
);

export const fetchOrganizationIncomeHistory = createAsyncThunk(
  "payout/fetchOrganizationIncomeHistory",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/payouts/organization-income?${queryString}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/api_service";
import buildQueryString from "helpers/buildQueryString";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ userType, queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/users/${userType === "users" ? "" : userType}?${queryString}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error ");
    }
  }
);

export const fetchUsersDetails = createAsyncThunk(
  "user/fetchUsersDetails",
  async ({ userId, userType }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.get(`/${userType}/${userId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchUserMe = createAsyncThunk(
  "user/fetchUserMe",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.get(`/users/me`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);
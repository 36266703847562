import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/api_service";
import buildQueryString from "helpers/buildQueryString";
// import buildQueryString from "helpers/buildQueryString";

export const getTotalRevenue = createAsyncThunk(
  "analytic/getTotalRevenue",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/analytics/total-revenue`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const getTotalLiveViewers = createAsyncThunk(
  "analytic/getTotalLiveViewers",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/analytics/total-live-viewers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const getTotalLiveStreamers = createAsyncThunk(
  "analytic/getTotalLiveStreamers",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/analytics/total-live-streamers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const getTotalNewFollowers = createAsyncThunk(
  "analytic/getTotalNewFollowers",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/analytics/total-new-followers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchStreamersLeaderBoard = createAsyncThunk(
  "analytic/fetchStreamersLeaderBoard",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/analytics/streamers-leaderboard?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);


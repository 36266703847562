// src/redux/users/reducer.ts

import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "./actions";


// Declaration of initial state model
//that will be use in Components using "useSelector(state => state.sliceName)"
const initialState = {
  selectedRole: null,
  loading: {
    registerUser: false,
  },
  success: {
    registerUser: false,
  },
  error: {
    registerUser: false,
  },
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedRole: (state, action) => {
      console.log(action);
      state.selectedRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
  },
});

export const setSelectedRole = (payload) => (dispatch) => {
  dispatch(categorySlice.actions.setSelectedRole(payload));
};

export default categorySlice.reducer;

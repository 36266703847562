import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import justVibingLogoLight from "../../assets/images/just-vibing-logo-light.png";
import withRouter from "components/UI/Common/withRouter";
import { useSelector } from "react-redux";
import capitalizeFirstLetter from "helpers/capitalizeFirstLetter";

const Sidebar = props => {
  const { role } = useSelector(
    (state) => state.auth
  );

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              JV Live ADMIN
              <img src={justVibingLogoLight} height="50"/>
              {/* <img src={logo} /> */}
            </span>
            <span className="text-black d-flex gap-3 align-items-center" style={{ fontSize: "24px" }}>
              <img src={justVibingLogoLight}height="50" />
              JV Live <span style={{ }}>{capitalizeFirstLetter(role || "")}</span>
              {/* <img src={logoDark} alt="" height="50" /> */}
            </span>
          </Link>

          <Link to="/" className="logo logo-light d-flex align-items-center gap-2">
            <img src={justVibingLogoLight} alt="" height="50"/>
            <span className="text-white" style={{ fontSize: "20px" }}>
              JV Live <span style={{ }}>{capitalizeFirstLetter(role || "")}</span>
              {/* <img src={logoLightPng} alt="" height="19" /> */}
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));

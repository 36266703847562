import React from 'react'
import { Alert } from 'reactstrap'

const AlertMessage = ({ isShow, message, icon, color }) => {
  return (
    isShow ? 
      <Alert className={` rounded-4 border-r mt-2 d-flex align-items-center gap-3 text-${color}`} color={color}>
        {icon && <span>{icon}</span>}
        <span className=""> {message}</span>
      </Alert> : 
      null
  )
}

export default AlertMessage
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/api_service";

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/public/${payload.roleType}/register`, payload.data);
      // console.log("--ACTION registerUser API RESPONSE: ", response);
      console.log(response.data);
      return response.data;
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error registerUser");
    }
  }
);

export const getQuestionListData = createAsyncThunk(
  "auth/getQuestionListData",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/public/security-questions`);
      // console.log("--ACTION registerUser API RESPONSE: ", response);
      console.log(response.data);
      return response.data;
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error getQuestionListData");
    }
  }
);

export const getUserTypeListData = createAsyncThunk(
  "auth/getUserTypeListData",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/public/users/group?type=${payload.userType}`);
      // console.log("--ACTION registerUser API RESPONSE: ", response);
      console.log(response.data);
      return response.data;
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error getUserTypeListData");
    }
  }
);

export const getCategoryListData = createAsyncThunk(
  "auth/getCategoryListData",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/public/stream/interest`);
      // console.log("--ACTION registerUser API RESPONSE: ", response);
      console.log(response.data);
      return response.data;
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error getCategoryListData");
    }
  }
);
import { createSlice } from "@reduxjs/toolkit";
import { getCookie, removeCookie } from "helpers/cookies";
import { createUser, loginUsingEmail } from "./actions";

const initialState = {
  userInfo: null,
  token: getCookie("signinUserData")?.tokens?.access
  ? getCookie("signinUserData")?.tokens?.access
  : null,
  isFinishedProfile: getCookie("signinUserData")?.isFinishedProfile
  ? getCookie("signinUserData")?.isFinishedProfile
  : false,
  isAuthenticated: getCookie("signinUserData")?.tokens?.access
  ? true
  : false,
  role: getCookie("signinUserData")?.role
  ? getCookie("signinUserData")?.role
  : null,
  roles: getCookie("signinUserData")?.roles
  ? getCookie("signinUserData")?.roles
  : [],
  isLoading: false,
  error: null,
  success: null,
  userId: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutState: (state, action) => {
      removeCookie("signinUserData");
      state.error = action?.payload?.message;
    },
    setNotAllowedUserRoleErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
    // successSignupMessage: (state, action) => {
    //   state.error = action.payload.message;
    // },
    forceClearAllAuthState: () => {
      removeCookie("signinUserData");
      return initialState
    },
    logoutStateUnauthorized: (state, action) => {
      removeCookie("signinUserData");
      state.error = action.payload.message;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUsingEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUsingEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        const signinUser = getCookie("signinUserData");
        state.role = signinUser?.role
        ? signinUser?.role
        : "";
        state.isAuthenticated = true;
        state.success = true;
      })
      .addCase(loginUsingEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.m || "An error occurred";
        state.message = action.payload.m || "An error occurred";
      })

      // CREATE USER START HERE
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.m || "An error occurred";
        state.error = action.payload.m || "An error occurred in createUser";
      })
      // CREATE USER END HERE
  },
});

export const { 
  forceClearAllAuthState,
  logoutState,
  setNotAllowedUserRoleErrorMessage,
  logoutStateUnauthorized
} = authSlice.actions;

export default authSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { fetchActiveUser, fetchListTrendingStreams, fetchStreamersLeaderBoard, fetchTotalGiftSends, fetchTotalNewStreamers, fetchTotalNewUsers } from "./actions";

const initialState = {
  data: null,
  totalNewUsers: 0,
  totalNewStreamers: 0,
  totalGiftSends: 0,
  streamerLeaderBoard: null,
  isLoading: false,
  error: null,
  success: null,
  message: null,
  activeOrganizations: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  activeAgencies: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  activeStreamers: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  trendingStreams: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalNewUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTotalNewUsers.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalNewUsers = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchTotalNewUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchTotalNewStreamers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTotalNewStreamers.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalNewStreamers = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchTotalNewStreamers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchTotalGiftSends.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTotalGiftSends.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalGiftSends = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchTotalGiftSends.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchStreamersLeaderBoard.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchStreamersLeaderBoard.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.streamerLeaderBoard = data;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchStreamersLeaderBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      // THIS IS REUSABLE FOR FETCHING Active USERS, ORGANIZATION, AGENCIES AND STREAMERS
      .addCase(fetchActiveUser.pending, (state, action) => {
        const { userType } = action.meta.arg
        switch (userType) {
          case "organizations":
            state.activeOrganizations.isLoading = true;
            break;
          case "agencies":
            state.activeAgencies.isLoading = true;
            break;
          case "streamers":
            state.activeStreamers.isLoading = true;
            break;
        
          default:
            break;
        }
      })
      .addCase(fetchActiveUser.fulfilled, (state, action) => {
        const { userType } = action.meta.arg
        const data = action.payload.d;
        const message = action.payload.m;

        switch (userType) {
          case "organizations":
            state.activeOrganizations.data = data;
            state.activeOrganizations.isLoading = false;
            state.activeOrganizations.success = `Successfully Fetched ${userType} Data`;
            state.activeOrganizations.message = message;
            break;
          case "agencies":
            state.activeAgencies.data = data;
            state.activeAgencies.isLoading = false;
            state.activeAgencies.success = `Successfully Fetched ${userType} Data`;
            state.activeAgencies.message = message;
            break;
          case "streamers":
            state.activeStreamers.data = data;
            state.activeStreamers.isLoading = false;
            state.activeStreamers.success = `Successfully Fetched ${userType} Data`;
            state.activeStreamers.message = message;
            break;
        
          default:
            break;
        }

      })
      .addCase(fetchActiveUser.rejected, (state, action) => {
        const { userType } = action.meta.arg
        switch (userType) {
          case "organizations":
            state.activeOrganizations.isLoading = false;
            state.activeOrganizations.error = action.payload.m || `Error Fetched ${userType} Data`;
            state.activeOrganizations.message = action.payload.m || "An error occurred";
            break;
          case "agencies":
            state.activeAgencies.isLoading = false;
            state.activeAgencies.error = action.payload.m || `Error Fetched ${userType} Data`;
            state.activeAgencies.message = action.payload.m || "An error occurred";
            break;
          case "streamers":
            state.activeStreamers.isLoading = false;
            state.activeStreamers.error = action.payload.m || `Error Fetched ${userType} Data`;
            state.activeStreamers.message = action.payload.m || "An error occurred";
            break;
        
          default:
            break;
        }
      })

      .addCase(fetchListTrendingStreams.pending, (state, action) => {
        state.trendingStreams.isLoading = true;
      })
      .addCase(fetchListTrendingStreams.fulfilled, (state, action) => {
        const data = action.payload.d;
        console.log(action.payload.d)
        const message = action.payload.m;
        state.trendingStreams.data = data;
        state.trendingStreams.isLoading = false;
        state.trendingStreams.success = `Successfully Fetched Data`;
        state.trendingStreams.message = message;
      })
      .addCase(fetchListTrendingStreams.rejected, (state, action) => {
        state.trendingStreams.isLoading = false;
        state.trendingStreams.success = false;
        state.trendingStreams.error = action.payload.m || `Error Fetched Data`;
        state.trendingStreams.message = action.payload.m || "An error occurred";
      })

  },
});

export const { 
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
